@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700&display=swap");

@layer base {
  body {
    @apply font-[IBM+Plex+Mono];
    @media screen {
    }
    overflow: hidden;
  }

  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

.content-div:hover {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 1),
    rgba(63, 81, 181, 1)
  ) !important;
  border-radius: 10px;
}

::-webkit-scrollbar {
  display: none;
}
